exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-templates-child-project-jsx-content-file-path-src-content-portfolio-reingold-ladrc-mdx": () => import("./../../../src/templates/childProject.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/reingold/ladrc.mdx" /* webpackChunkName: "component---src-templates-child-project-jsx-content-file-path-src-content-portfolio-reingold-ladrc-mdx" */),
  "component---src-templates-child-project-jsx-content-file-path-src-content-portfolio-reingold-phoenix-mdx": () => import("./../../../src/templates/childProject.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/reingold/phoenix.mdx" /* webpackChunkName: "component---src-templates-child-project-jsx-content-file-path-src-content-portfolio-reingold-phoenix-mdx" */),
  "component---src-templates-child-project-jsx-content-file-path-src-content-portfolio-reingold-st-jude-global-mdx": () => import("./../../../src/templates/childProject.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/reingold/st-jude-global.mdx" /* webpackChunkName: "component---src-templates-child-project-jsx-content-file-path-src-content-portfolio-reingold-st-jude-global-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-portfolio-cheetah-conservation-fund-index-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/cheetah-conservation-fund/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-portfolio-cheetah-conservation-fund-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-portfolio-devsmart-group-index-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/devsmart-group/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-portfolio-devsmart-group-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-portfolio-endangered-species-revenge-index-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/endangered-species-revenge/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-portfolio-endangered-species-revenge-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-portfolio-mr-ellie-pooh-index-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/mr-ellie-pooh/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-portfolio-mr-ellie-pooh-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-portfolio-natura-11-y-index-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/natura11y/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-portfolio-natura-11-y-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-portfolio-nyc-oti-index-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/nyc-oti/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-portfolio-nyc-oti-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-portfolio-reingold-index-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/reingold/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-portfolio-reingold-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-portfolio-unicef-index-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/unicef/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-portfolio-unicef-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-portfolio-visionlearning-index-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/carl-avidano-ux/src/content/portfolio/visionlearning/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-portfolio-visionlearning-index-mdx" */)
}

