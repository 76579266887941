/*

In this file:

// A. Active Styles
// B. Button Over Text Styles

*/

//////////////////////////////////////////////
// A. Active Styles
//////////////////////////////////////////////

export const activeLinkStyles = {
    'fontWeight': 'var(--font-weight-bold)',
    'textDecoration': 'underline'
}


//////////////////////////////////////////////
// B. Button Over Text Styles
//////////////////////////////////////////////

export const buttonOverTextStyles = {
    '--button-icon-only-size': '2.5em'
};